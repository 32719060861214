import logo from './logo.svg';
import './App.css';
import NavBar from './components/NavBar';
import ImageCard from './components/ImageCard';
import FactsCard from './components/FactsCard';
import {useState} from 'react'
// function fetchImages(){
//   const api_url = "https://cataas.com/cat";
//   const res  = fetch(api_url)
//   return res
// }


// function fetchFacts() {
//   const api_url = "https://catfact.ninja/fact";
//   const res = fetch(api_url)
//   return res
// }



function App() {
  const [imageurl, setimageurl] = useState({});
  const [fact, setfact] = useState("");


  function eventHandle() {
    const fact_api_url = "https://catfact.ninja/fact";
    const fact_res = fetch(fact_api_url).then((res)=>res.json()).then((data)=>setfact(data.fact));

    const img_api_url = "https://cataas.com/cat";
    const img_res = fetch(img_api_url)
      .then((res) => res.blob())
      .then((blob) =>{ 
        setimageurl({"image_url":URL.createObjectURL(blob),"query_time":Date.now()})
        console.error(imageurl);
});
  }
  return (
    <div className="App">
      <NavBar></NavBar>
      <ImageCard key={imageurl.query_time} imageurl={imageurl}></ImageCard>
      <FactsCard catfacts={fact}></FactsCard>
      <button onClick={() => eventHandle()}>
        click to get intresting facts about cats
      </button>
    </div>
  );
}

export default App;
